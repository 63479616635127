<template>
  <b-card
    id="booking-card"
    no-body
    class="cursor-pointer mb-2 p-3 px-4 shadow"
    @click="showBooking"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <b-img
          :src="pictureUrl"
          :alt="$t('Accommodation picture')"
          class="card-img mr-4"
        />

        <div>
          <h5 class="mb-1 three-dots">
            {{ accommodationName }}
          </h5>

          <div v-if="showDates" class="three-dots">
            <small>{{ dates }}</small>
          </div>

          <div v-if="showGuests" class="three-dots">
            <small>
              {{ guestsText }}
            </small>
          </div>
        </div>
      </div>
      <b-button class="d-none d-md-block" variant="outline-primary">
        {{ $t("Manage booking button") }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BImg, BButton } from "bootstrap-vue";
import { getGuestsText } from "@/utils/methods";
import { formatDateStringToDayDayShortMonthYear } from "@/utils/formatters";
import { ACCOMMODATION_PLACEHOLDER_SM } from "@/utils/consts";

export default {
  components: {
    BCard,
    BImg,
    BButton
  },
  props: {
    booking: {
      type: Object,
      required: true
    },
    hideGuests: {
      type: Boolean,
      default: false
    },
    hideDates: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    accommodationName() {
      return this.booking?.accommodation?.name || null;
    },
    pictureUrl() {
      return (
        this.booking?.accommodation?.coverPicture?.url ||
        ACCOMMODATION_PLACEHOLDER_SM
      );
    },
    checkinDate() {
      return formatDateStringToDayDayShortMonthYear(
        this.booking?.checkin,
        this.$i18n.locale
      );
    },
    checkoutDate() {
      return formatDateStringToDayDayShortMonthYear(
        this.booking?.checkout,
        this.$i18n.locale
      );
    },
    dates() {
      const dates = [];
      if (this.checkinDate) dates.push(this.checkinDate);
      if (this.checkoutDate) dates.push(this.checkoutDate);
      return dates.length ? dates.join(" - ") : null;
    },
    showDates() {
      return !this.hideDates;
    },
    showGuests() {
      return !this.hideGuests;
    },
    guestsText() {
      if (!this.booking) return null;
      return getGuestsText(
        {
          adults: this.booking.adults,
          children: this.booking.children,
          babies: this.booking.babies
        },
        this.$i18n.locale
      );
    },
    bookingStatus() {
      return this.booking?.status || null;
    }
  },
  methods: {
    showBooking() {
      this.$router.push({
        name: "foravila-booking",
        params: {
          localizator: this.booking.localizator
        }
      });
    }
  }
};
</script>

<style lang="scss">
#booking-card {
  .card-img {
    width: 100px;
    height: 80px;
    object-fit: cover;
  }
}
</style>
