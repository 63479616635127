<template>
  <div id="bookings-list">
    <!-- <h2 class="text-primary mb-3">
      Hola Gabriel Nadal,
    </h2> -->
    <template v-if="loadingBookings">
      <b-row>
        <b-col cols="12">
          <h3 class="mb-4">
            {{ $t("Current bookings") }}
          </h3>
        </b-col>
        <b-col
          v-for="index in placeholderItems"
          :key="`booking-placeholder-${index}`"
          cols="12"
        >
          <booking-card-placeholder />
        </b-col>
      </b-row>
    </template>

    <template v-else>
      <b-row>
        <b-col cols="12">
          <h4>
            {{ $t("Current bookings") }}
          </h4>
          <p>{{ $t("Current bookings subtitle") }}</p>
        </b-col>
        <template v-if="currentBookings.length">
          <b-col
            v-for="booking in currentBookings"
            :key="`current-booking-${booking.uuid}`"
            cols="12"
          >
            <booking-card :booking="booking" />
          </b-col>
        </template>
        <template v-else>
          <b-col cols="12">
            <div
              class="border px-4 py-7 bg-white d-flex flex-column align-items-center"
            >
              <div class="no-current-booking-icon ">
                <img
                  src="@/assets/foravila/images/illustrations/hand.svg"
                  alt=""
                />
              </div>
              <div class="font-weight-bold text-center h4 mt-4">
                {{ $t("pages.bookings.noBookings") }}
              </div>
              <div class="text-center">
                {{ $t("pages.bookings.noBookingsText") }}
              </div>
              <b-button
                variant="primary"
                :href="agencyWebsite"
                target="blank"
                class="mt-4 px-6 py-4 bg-gradient-primary"
              >
                {{ $t("pages.bookings.goToWebsite") }}
              </b-button>
            </div>
          </b-col>
        </template>
      </b-row>

      <b-row v-if="pastBookings.length" class="mt-4">
        <b-col cols="12">
          <h5>
            {{ $t("Past bookings") }}
          </h5>
        </b-col>
        <b-col
          v-for="booking in pastBookings"
          :key="`past-booking-${booking.uuid}`"
          cols="12"
        >
          <booking-card :booking="booking" hide-guests />
        </b-col>
      </b-row>
      <airport-transfer-service-banner
        v-if="currentBookings.length"
        class="mt-2 mt-md-4"
      />
    </template>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import BookingCard from "@/views/foravila/bookings/list/components/BookingCard.vue";
import { notifyError } from "@/utils/methods";
import BookingCardPlaceholder from "@/views/foravila/bookings/list/components/BookingCardPlaceholder.vue";
import AirportTransferServiceBanner from "@/views/foravila/bookings/list/components/AirportTransferServiceBanner.vue";
import { AGENCY_WEBSITE } from "@appConfig";

export default {
  components: {
    BRow,
    BCol,
    BookingCard,
    BButton,
    BookingCardPlaceholder,
    AirportTransferServiceBanner
  },
  computed: {
    loadingBookings() {
      return this.$store.getters["bookings/loading"];
    },
    currentBookings() {
      return this.$store.getters["bookings/currentBookings"];
    },
    pastBookings() {
      return this.$store.getters["bookings/pastBookings"];
    },
    loadingGlobalServices() {
      return this.$store.getters["services/loadingGlobalServices"];
    },
    globalServices() {
      return this.$store.getters["services/globalServices"];
    },
    userData() {
      return JSON.parse(localStorage.getItem("foravilaGuestsUserData"));
    },
    clientUuid() {
      return this.userData.client.uuid;
    },
    client() {
      return this.$store.getters["client/client"];
    },
    agencyWebsite() {
      return `${AGENCY_WEBSITE}?utm_source=intranet-guests`;
    },
    placeholderItems() {
      const currentBreakpoint = this.$store.getters["app/currentBreakPoint"];
      return currentBreakpoint === "xl" || currentBreakpoint === "lg" ? 3 : 4;
    }
  },
  created() {
    if (!this.client) {
      this.fetchClient();
    }

    this.fetchBookings();

    if (!this.globalServices?.length && !this.loadingGlobalServices) {
      this.fetchGlobalServices();
    }
  },
  methods: {
    fetchBookings() {
      this.$store
        .dispatch("bookings/fetchBookings", { pagination: false })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.text")
          )
        );
    },
    fetchClient() {
      if (!this.clientUuid) return null;
      this.$store
        .dispatch("client/fetchClient", this.clientUuid)
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.text")
          )
        );
    },
    fetchGlobalServices() {
      this.$store
        .dispatch("services/fetchGlobalServices", { pagination: false })
        .catch(() => {});
    }
  }
};
</script>
<style lang="scss">
.no-current-booking-icon {
  width: 48px;
}
</style>
