<template>
  <b-row>
    <b-col>
      <div id="airport-transfer-banner" class="rounded">
        <div class="airport-tansfer-banner-inner p-4 p-lg-6 d-flex flex-column">
          <h1
            class="text-uppercase fr-title-mobile-size fr-title-desktop-size  text-white fr-letter-spacing fr-font-weight-super-bold"
          >
            {{ $t("pages.bookings.transferBanner.title") }}
          </h1>
          <h4
            class="text-white fr-subtitle-mobile-size fr-subtitle-desktop-size font-weight-light"
          >
            {{ $t("pages.bookings.transferBanner.subtitle") }}
          </h4>
          <div>
            <b-button
              variant="primary"
              class="font-weight-bold px-5 py-3 mt-2 mt-md-4"
              @click="onButtonClicked"
            >
              {{ $t("pages.bookings.transferBanner.button") }}
            </b-button>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton
  },
  methods: {
    onButtonClicked() {
      this.$router.push({ name: "foravila-services-airport-transfer" });
    }
  }
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
#airport-transfer-banner {
  margin-bottom: 2rem;
  background-image: url("~@/assets/foravila/images/services/transfer-banner.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 220px;
  @include media-breakpoint-up(md) {
    height: 320px;
  }
  .airport-tansfer-banner-inner {
    width: 100%;
  }
  @include media-breakpoint-up(md) {
    .airport-tansfer-banner-inner {
      width: 60%;
    }
  }
}
</style>
