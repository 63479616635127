<template>
  <b-card
    no-body
    class="house-card blink"
  >
    <div class="d-flex align-items-center">
      <div class="mr-4">
        <b-img
          :src="pictureUrl"
          :alt="$t('Accommodation picture')"
          class="card-img"
        />
      </div>

      <div class="house-card-text-wrapper p-3">
        <b-row class="mb-3">
          <b-col
            cols="8"
            class="house-name-placeholder"
          />
        </b-row>
        <b-row class="mb-2">
          <b-col
            cols="6"
            class="arrangement-placeholder"
          />
        </b-row>
        <b-row>
          <b-col
            cols="7"
            class="arrangement-placeholder"
          />
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BImg,
} from 'bootstrap-vue'
import { ACCOMMODATION_PLACEHOLDER_SM } from '@/utils/consts'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
  },
  computed: {
    pictureUrl() {
      return ACCOMMODATION_PLACEHOLDER_SM
    },
  },
}
</script>

<style lang="scss" scoped>
  .house-card {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
    border: 1px solid lightgray;
    .card-img {
      width: 120px;
      height: 90px;
      object-fit: cover;
    }
  }
  .house-card-text-wrapper {
    width: 100%;
    .house-name-placeholder {
      background-color: lightgray;
      height: 20px;
    }
    .arrangement-placeholder {
      background-color: lightgray;
      height: 10px;
    }
  }
</style>
